import { openCreateReport, useLayout } from '@/stores/layout';
import { Button } from '@skand/ui';
import { CreateOrEditReport } from './CreateReport/CreateOrEditReport';
import { useState } from 'react';

export const ReportPageActions = () => {
  const showCreateReport = useLayout(state => state.showCreateReport);
  const showEditReport = useLayout(state => state.showEditReport);
  const [enabledFilterMenu, setEnabledFilterMenu] = useState(false);

  return (
    <>
      <div className="flex items-center justify-between b-b-1 b-b-neutral-300 b-b-solid p-b-3 p-t-8">
        <h1 className="color-neutral-800 typo-heading-3">Reports</h1>
        <Button
          active={showCreateReport}
          className="w-136px"
          filled={!showCreateReport}
          onClick={openCreateReport}
          primary
          size="s"
        >
          Create report
        </Button>
      </div>

      {showCreateReport ? (
        <CreateOrEditReport
          enabledFilterMenu={enabledFilterMenu}
          mode={'create'}
          setEnabledFilterMenu={setEnabledFilterMenu}
        />
      ) : null}

      {showEditReport ? (
        <CreateOrEditReport
          enabledFilterMenu={enabledFilterMenu}
          mode={'edit'}
          setEnabledFilterMenu={setEnabledFilterMenu}
        />
      ) : null}
    </>
  );
};
